
.goodsInfoDetail {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 20px;
    margin-right: 20px;
    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 50px;
        border-bottom: 1px solid #EEE;
        .top-tiele {
            font-size: 16px;
            color: #333;
            line-height: 50px;
        }
    }
    .middle {
        display: flex;
        flex-direction: column;
        height: 90%;
        margin-top: 40px;
        .content-body {
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                // padding-bottom: 18px;
                .el-scrollbar__view {
                    height: 100%;
                }
            }
        }
        .g-info-title {
            display: flex;
            flex-direction: row;
            height: 50px;
            font-size: 16px;
            color: #333;
            line-height: 50px;
            background: #F6F6F6;
            .g-text {
                flex: 2.8;
                margin-left: 39px;
            }
            .g-item {
                flex: 1;
            }
        }
        .g-content-fillin {
            display: flex;
            flex-direction: column;
            .g-content {
                display: flex;
                flex-direction: row;
                height: 70px;
                background: #FBFBFD ;
                border-bottom: 1px solid #EEEEEE;
                .t-ginfo {
                    flex: 2.8;
                    display: flex;
                    flex-direction: row;
                    height: 70px;
                    margin-left: 39px;
                    font-size: 16px;
                    color: #333333;
                }
                .t-item {
                    flex: 1;
                    height: 70px;
                    font-size: 16px;
                    color: rgba(51, 51, 51, .8);
                    line-height: 70px;
                }
                .g-title {
                    width: 272px;
                    height: 44px;
                    font-size: 16px;
                    color: #333;
                    margin-left: 8px;
                    margin-top: 12px;
                    display:-webkit-box;
                    text-overflow:ellipsis;
                    overflow:hidden;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient:vertical;
                }
                .t-parse {
                    text-align: center;
                }
            }
            .g-fillin {
                display: flex;
                flex-direction: column;
                .item {
                    display: flex;
                    flex-direction: row;
                    border-bottom: 1px solid #EEEEEE;
                }
                .item-text {
                    flex: 1;
                    height: 22px;
                    display: flex;
                    flex-direction: row;
                    margin: 24px 0 24px 63px;
                }
                .item-input {
                    flex: 3.5;
                    font-size: 14px;
                    color: #333;
                    margin: 24px 27px 15px 0;
                }
            }
        }
    }
}
